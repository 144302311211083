<template>
  <app-tab-view-container :tabs="tabs" :loading="loading">
    <template #append>
      <v-row v-if="hasAdminView" class="mx-4 mt-12 flex-auto">
        <app-api-log :data="currentLimitAndStatus" api-name="/current-limit-and-status" />
      </v-row>
    </template>
  </app-tab-view-container>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import AppTabViewContainer from '@/shared/components/AppTabViewContainer';
import TabGeneralSettings from '@/modules/User/components/Settings/TabGeneralSettings';
import TabEmailNotifications from '@/modules/User/components/Settings/TabEmailNotifications';
import TabCompanyUsers from '@/modules/User/components/Settings/TabCompanyUsers';

export default {
  name: 'Settings',

  components: {
    AppTabViewContainer
  },

  data: () => ({
    currentLimitAndStatus: null,
    loading: false
  }),

  computed: {
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('settings.general'),
          component: TabGeneralSettings,
          condition: this.permissions.settings.tabGeneral
        },
        {
          id: 2,
          name: this.$t('settings.emailNotifications.title'),
          component: TabEmailNotifications,
          condition: this.permissions.settings.tabEmailNotifications
        },
        {
          id: 3,
          name: this.$t('settings.companyUsers.title'),
          component: TabCompanyUsers,
          condition: this.permissions.settings.tabCompanyUsers
        }
      ];
    }
  },

  watch: {
    hasAdminView: {
      handler() {
        if (!this.hasAdminView || !this.isFactoring) {
          return;
        }
        this.fetchLimit();
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchLimitAndStatus']),

    async fetchLimit() {
      const { response, error } = await this.fetchLimitAndStatus();
      if (error) {
        return;
      }
      this.currentLimitAndStatus = response;
    }
  }
};
</script>
