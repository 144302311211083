<template>
  <v-container fluid :class="{ 'd-flex justify-center align-center': loading || error }">
    <app-error-alert v-if="error" />
    <app-spinner v-else-if="loading" />
    <template v-else>
      <v-row>
        <v-col :cols="isMobile ? 12 : 6" class="tab__col">
          <h3>{{ $t('settings.companyUsers.title') }}</h3>
          <v-row no-gutters>
            <v-col>
              <p>{{ $t('settings.companyUsers.info') }}</p>
              <v-row dense class="mt-4">
                <template v-for="role in companyUserRoles">
                  <v-col :key="role" cols="auto">
                    <app-tooltip :text="$t(`settings.companyUsers.rolesInfo.${role}`)">
                      <div class="d-flex align-center">
                        {{ role | formatRole }}
                        <icon-info class="grey--text ml-2 mr-4" />
                      </div>
                    </app-tooltip>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="mt-4 mb-6" />

          <app-alert
            v-if="userLimitExhausted"
            color="primary"
            icon="$iconInfo"
            text="settings.companyUsers.limitReached"
            class="mb-4"
          />
          <app-btn
            :disabled="userLimitExhausted"
            class="mb-4"
            @click="setAction({ param: queryParam.CREATE_COMPANY_USER, value: true })"
          >
            {{ $t('settings.companyUsers.addUser') }}
          </app-btn>

          <template v-for="user in users">
            <setting-box :key="user.id" class="my-4">
              <template #actions>
                <app-icon-btn
                  class="settings__delete"
                  color="button-link"
                  icon="icon-pen"
                  size="18"
                  @click="setAction({ param: queryParam.EDIT_COMPANY_USER, value: user.id })"
                />
                <app-icon-btn
                  class="settings__delete"
                  color="button-link"
                  icon="icon-trash-can"
                  size="18"
                  @click="setAction({ param: queryParam.DELETE_COMPANY_USER, value: user.id })"
                />
              </template>

              <template #title>
                <div class="d-flex caption">
                  <app-link-btn
                    color="button-link"
                    title="users.resetPassword"
                    variant="muted"
                    @click="
                      setAction({ param: queryParam.RESET_COMPANY_USER_PASSWORD, value: user.id })
                    "
                  />
                  <span v-if="!user.isActive" class="ml-4">
                    {{ $t('settings.companyUsers.inactive') }}
                  </span>
                </div>
              </template>

              <v-row dense class="flex-column">
                <v-col>
                  <v-row dense justify="space-between">
                    <v-col cols="auto">{{ $t('name') }}</v-col>
                    <v-col cols="auto" class="semi-bold">{{ user.name }}</v-col>
                  </v-row>
                  <v-row dense justify="space-between">
                    <v-col cols="auto">{{ $t('email') }}</v-col>
                    <v-col cols="auto" class="semi-bold">{{ user.email }}</v-col>
                  </v-row>
                  <v-row dense justify="space-between">
                    <v-col cols="auto">{{ $t('users.role') }}</v-col>
                    <v-col cols="auto" class="semi-bold">
                      <app-tooltip :text="$t(`settings.companyUsers.rolesInfo.${user.role}`)">
                        <div class="d-flex align-center">
                          {{ user.role | formatRole }}
                          <icon-info class="grey--text ml-2" />
                        </div>
                      </app-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </setting-box>
          </template>
        </v-col>
      </v-row>
    </template>

    <component
      :is="selectedAction.component"
      v-if="initializedUsers"
      v-model="showActionDialog"
      :user="getUserByAction(selectedAction.param)"
      @success="fetchUserList"
    />
  </v-container>
</template>

<script>
const MAX_USERS = 10;

import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import { COMPANY_USER_ROLES } from '@/statics/role';
import SettingBox from '@/modules/User/components/Settings/SettingBox';
import CreateCompanyUserDialog from '@/modules/User/components/Settings/Actions/CreateCompanyUserDialog';
import EditCompanyUserDialog from '@/modules/User/components/Settings/Actions/EditCompanyUserDialog';
import DeleteCompanyUserDialog from '@/modules/User/components/Settings/Actions/DeleteCompanyUserDialog';
import ResetCompanyUserPasswordDialog from '@/modules/User/components/Settings/Actions/ResetCompanyUserPasswordDialog';

export default {
  name: 'TabCompanyUsers',

  components: {
    SettingBox
  },

  data: () => ({
    users: [],
    initializedUsers: false,
    error: false,
    loading: false
  }),

  computed: {
    companyUserRoles() {
      return COMPANY_USER_ROLES;
    },
    userLimitExhausted() {
      return (
        this.users.length >= (this.$auth.check(this.role.CREDITOR) ? MAX_USERS : MAX_USERS - 1)
      );
    },
    actions() {
      return {
        [this.queryParam.CREATE_COMPANY_USER]: {
          param: this.queryParam.CREATE_COMPANY_USER,
          component: CreateCompanyUserDialog,
          callback: () => this.setQueryParam(this.queryParam.CREATE_COMPANY_USER, true),
          permission: !this.userLimitExhausted
        },
        [this.queryParam.EDIT_COMPANY_USER]: {
          param: this.queryParam.EDIT_COMPANY_USER,
          component: EditCompanyUserDialog,
          callback: (userId) => this.setQueryParam(this.queryParam.EDIT_COMPANY_USER, userId)
        },
        [this.queryParam.DELETE_COMPANY_USER]: {
          param: this.queryParam.DELETE_COMPANY_USER,
          component: DeleteCompanyUserDialog,
          callback: (userId) => this.setQueryParam(this.queryParam.DELETE_COMPANY_USER, userId)
        },
        [this.queryParam.RESET_COMPANY_USER_PASSWORD]: {
          param: this.queryParam.RESET_COMPANY_USER_PASSWORD,
          component: ResetCompanyUserPasswordDialog,
          callback: (userId) =>
            this.setQueryParam(this.queryParam.RESET_COMPANY_USER_PASSWORD, userId)
        }
      };
    },
    showActionDialog: {
      get() {
        return !!this.selectedAction.component;
      },
      set() {
        this.setQueryParam(this.selectedAction.param, undefined);
      }
    },
    selectedAction() {
      return (
        Object.values(this.actions).find(
          (action) =>
            !!this.$route.query[action.param] &&
            action.permission !== false &&
            this.hasFulfilledCondition(action) &&
            this.getUserByAction(action.param) !== null
        ) ?? {}
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchCompanyUsers']),

    async fetchUserList() {
      this.loading = true;
      const { users, error } = await this.fetchCompanyUsers();

      if (error) {
        return (this.error = true);
      }

      this.users = users;
      this.loading = false;
    },

    setAction({ param, value }) {
      this.actions[param].callback(value);
    },

    getUserByAction(param) {
      if (this.getQueryParam(param) === true) {
        return;
      }

      return this.users.find((user) => user.id === this.getQueryParam(param)) ?? null;
    }
  },

  async created() {
    await this.fetchUserList();
    this.initializedUsers = true;
  }
};
</script>
