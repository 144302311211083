<template>
  <setting-box>
    <template #actions>
      <app-icon-btn
        class="settings__delete"
        color="button-link"
        icon="icon-trash-can"
        size="18px"
        @click="onDelete"
      />
    </template>

    <template #title>
      <p class="semi-bold">{{ item.email }}</p>
    </template>

    <v-row dense :class="{ 'flex-column': isMobile }">
      <template v-for="(key, index) in Object.keys(setting)">
        <v-col
          v-if="hasFulfilledCondition(settingOptions[key])"
          :key="index"
          cols="auto"
          class="d-inline-flex align-center mr-10"
        >
          <app-checkbox v-model="setting[key]" :label="settingOptions[key].title" />
          <app-tooltip :text="settingOptions[key].tooltip" />
        </v-col>
      </template>
    </v-row>
    <v-row v-if="changesMade">
      <v-col cols="auto">
        <app-btn outlined title="navigation.cancel" @click="onCancel" />
      </v-col>
      <v-col cols="auto">
        <app-btn title="navigation.save" :loading="loading" @click="onSubmit" />
      </v-col>
    </v-row>
  </setting-box>
</template>

<script>
import SettingBox from '@/modules/User/components/Settings/SettingBox';

export default {
  name: 'EmailNotificationSettingBox',

  components: {
    SettingBox
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    settingOptions: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    email: '',
    setting: null
  }),

  computed: {
    changesMade() {
      return Object.keys(this.setting).some((key) => this.setting[key] !== this.item.setting[key]);
    }
  },

  methods: {
    onCancel() {
      this.setting = { ...this.item.setting };
    },
    onSubmit() {
      this.$emit('submit', { ...this.setting });
    },
    onDelete() {
      this.$emit('delete', { ...this.setting });
    }
  },

  created() {
    this.email = this.item.email;
    this.setting = { ...this.item.setting };
  }
};
</script>
