<template>
  <v-container fluid :class="{ 'd-flex justify-center align-center': loading || error }">
    <app-error-alert v-if="error" />
    <app-spinner v-else-if="loading" />
    <template v-else>
      <v-row>
        <v-col :cols="isMobile ? 12 : 6" class="tab__col">
          <h3>{{ $t('settings.defaults.title') }}</h3>
          <v-row no-gutters>
            <v-col>
              <p>{{ $t('settings.defaults.info') }}</p>
              <app-labeled-data label="exposureType" class="mt-4">
                <app-select v-model="newExposureType" :items="exposureTypeItems" />
              </app-labeled-data>
              <app-btn
                v-if="defaultExposureType !== newExposureType"
                title="navigation.save"
                class="mt-2"
                style="float: right"
                :loading="loading"
                @click="submitDefaultExposureType"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col :cols="isMobile ? 12 : 6" class="tab__col">
          <h3 class="mt-12">{{ $t('settings.dataProtection.title') }}</h3>
          <app-labeled-data
            label="settings.dataProtection.cookieSettings"
            :value="$t(`settings.dataProtection.cookies${cookiesEnabled ? 'Enabled' : 'Disabled'}`)"
          >
            <template #action>
              <app-link-btn
                title="settings.dataProtection.revoke"
                variant="muted"
                @click="deleteCookieStorage"
              />
            </template>
          </app-labeled-data>
        </v-col>
      </v-row>

      <v-row v-if="permissions.tutorials" no-gutters align="center">
        <v-col :cols="isMobile ? 12 : 6" class="tab__col">
          <h3 class="mt-12">{{ $t('settings.defaults.tutorial') }}</h3>
          <tutorials-setting />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import TutorialsSetting from '@/modules/User/components/TutorialsSetting';
import exposureTypes from '@/statics/exposureTypes';
import showSnackbar from '@/helper/showSnackbar';
import { COOKIE_STORAGE } from '@/statics/storageKeys';
import { NAMESPACE } from '@/modules/User/store';
import { mapActions } from 'vuex';

export default {
  name: 'TabGeneralSettings',

  components: { TutorialsSetting },

  data: () => ({
    defaultExposureType: '',
    newExposureType: '',
    error: false,
    loading: false,
    cookiesEnabled: JSON.parse(localStorage.getItem(COOKIE_STORAGE))
  }),

  computed: {
    exposureTypeItems() {
      const items = exposureTypes.map((type) => ({
        label: type.translation,
        value: type.code
      }));

      if (!this.required) {
        items.unshift({
          label: this.$t('notSpecified'),
          value: ''
        });
      }

      return items;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDefaultExposureType', 'setDefaultExposureType']),

    async submitDefaultExposureType() {
      this.loading = true;
      const { error } = await this.setDefaultExposureType(this.newExposureType);
      this.loading = false;

      if (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return;
      }

      showSnackbar({ text: 'changesSaved', color: 'success', icon: '$iconCheck' });
      this.defaultExposureType = this.newExposureType;
    },

    deleteCookieStorage() {
      this.$eventHub.$emit('cookies-revoked');
      this.cookiesEnabled = false;
    }
  },

  async created() {
    this.$eventHub.$on('cookies-set', (value) => (this.cookiesEnabled = value));
    this.$on('hook:beforeDestroy', () => {
      this.$eventHub.$off('cookies-set');
    });

    this.loading = true;
    const { defaultExposureType, error } = await this.fetchDefaultExposureType();

    if (error) {
      return (this.error = true);
    }

    this.defaultExposureType = defaultExposureType;
    this.newExposureType = this.defaultExposureType;
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-input--switch {
  margin-top: 0;
}
</style>
