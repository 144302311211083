<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="users.updateUser"
      :primary-action-text="showConfirm ? 'navigation.send' : 'navigation.continue'"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="onCancel"
    >
      <template v-if="showConfirm">
        <app-alert :value="emailAlreadyAssigned" class="mb-4">
          {{ $t('users.userEmailAlreadyAssigned') }}
        </app-alert>

        <p>{{ $t('checkData') }}</p>
        <app-input-row dense label="name">
          <disabled-input-box>{{ formData.name }}</disabled-input-box>
        </app-input-row>
        <app-input-row dense label="email">
          <disabled-input-box>{{ formData.email }}</disabled-input-box>
        </app-input-row>
        <app-input-row dense label="users.role">
          <disabled-input-box>{{ formData.role | formatRole }}</disabled-input-box>
        </app-input-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <app-text-field v-model="formData.name" label="name" required />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field v-model="formData.email" label="email" rules="email|required" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="semi-bold mt-1">{{ $t('users.role') }}</div>
            <app-radio-group
              v-model="formData.role"
              :items="companyUserRoles"
              name="users.role"
              required
              :row="false"
            >
              <template #radio>
                <v-radio v-for="role in companyUserRoles" :key="role" :value="role" class="px-4">
                  <template #label>
                    {{ role | formatRole }}
                    <app-tooltip :text="$t(`settings.companyUsers.rolesInfo.${role}`)">
                      <icon-info class="grey--text mx-2" />
                    </app-tooltip>
                  </template>
                </v-radio>
              </template>
            </app-radio-group>
          </v-col>
        </v-row>
      </template>

      <template #secondaryAction>
        <app-btn
          block
          outlined
          :title="showConfirm ? 'navigation.goBack' : 'navigation.cancel'"
          @click="onCancel"
        />
      </template>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="users.updatedUser"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import { ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED } from '@/statics/errorCodes';
import { COMPANY_USER_ROLES } from '@/statics/role';
import showSnackbar from '@/helper/showSnackbar';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'EditCompanyUserDialog',

  mixins: [ShowDialogMixin],

  components: {
    DisabledInputBox
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    formData: {
      name: '',
      email: '',
      role: ''
    },
    emailAlreadyAssigned: false,
    showConfirm: false
  }),

  computed: {
    companyUserRoles() {
      return COMPANY_USER_ROLES;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['editCompanyUser']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      if (!this.showConfirm) {
        return (this.showConfirm = true);
      }

      this.loading = true;
      const payload = {
        userIdentifier: this.user.id,
        name: this.formData.name,
        email: this.formData.email,
        role: this.formData.role
      };

      const { error } = await this.editCompanyUser(payload);

      this.loading = false;
      if (error) {
        if (error.response?.data.error === ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED) {
          return (this.emailAlreadyAssigned = true);
        }

        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
    },

    onCancel() {
      if (this.showConfirm) {
        if (this.emailAlreadyAssigned) {
          this.emailAlreadyAssigned = false;
        }
        return (this.showConfirm = false);
      }

      this.open = false;
    },

    resetForm() {
      this.$refs.form?.reset();
      this.emailAlreadyAssigned = false;
      this.formData = {
        name: '',
        email: '',
        role: ''
      };
    }
  },

  created() {
    this.formData.email = this.user.email;
    this.formData.name = this.user.name;
    this.formData.role = this.user.role;
  }
};
</script>
