<template>
  <div class="settings__box">
    <v-row align="center">
      <v-col>
        <slot name="title"></slot>
      </v-col>
      <v-col cols="auto" class="settings__actions">
        <slot name="actions"></slot>
      </v-col>
    </v-row>

    <div class="mt-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingBox'
};
</script>

<style scoped>
.settings__box {
  background-color: #f2f2f2;
  padding: 0 1rem 1rem 1rem;
  position: relative;
}

.settings__actions {
  margin-top: -0.75rem;
  margin-right: -0.75rem;
}
</style>
