<template>
  <div>
    <h3>{{ $t('settings.emailNotifications.title') }}</h3>
    <v-row no-gutters>
      <v-col>
        <p class="mb-6">{{ $t('settings.emailNotifications.info') }}</p>

        <ValidationObserver ref="form" tag="form" @submit.prevent="addSetting">
          <v-row align="stretch" class="mt-4">
            <v-col>
              <app-text-field
                v-model="newEmail"
                label="settings.emailNotifications.addEmail"
                name="email"
                :rules="`email|unique:${existentSettingEmails}`"
                :show-supplement="false"
              />
            </v-col>
            <v-col cols="auto">
              <app-icon-btn
                :disabled="!newEmail"
                class="settings__add-btn"
                fab
                icon="icon-plus"
                size="16"
                small
                type="submit"
              />
            </v-col>
          </v-row>
        </ValidationObserver>

        <template v-for="(setting, index) in settings">
          <email-notification-setting-box
            :key="setting.email"
            :item="setting"
            :setting-options="settingOptions"
            :loading="loading"
            class="mt-4"
            @submit="submitChanges($event, index)"
            @delete="emailToDelete = setting.email"
          />
        </template>
      </v-col>
    </v-row>

    <app-dialog
      :value="!!emailToDelete"
      primary-action-text="navigation.yesUnsubscribe"
      secondary-action-text="navigation.noCancel"
      title="settings.emailNotifications.unsubscribe"
      :loading="loading"
      @submit="deleteSetting"
      @close="emailToDelete = undefined"
    >
      <p>
        {{ $t('settings.emailNotifications.confirmDeleteRequest', { email: emailToDelete }) }}
      </p>
    </app-dialog>
  </div>
</template>

<script>
import EmailNotificationSettingBox from '@/modules/User/components/MasterData/EmailNotificationSettingBox';
import {
  EmailNotificationSettingOption,
  EMAIL_NOTIFICATION_SETTING_OPTIONS
} from '@/modules/User/statics/emailNotificationSettingOption';
import showSnackbar from '@/helper/showSnackbar';
import { NAMESPACE } from '@/modules/User/store';
import { mapActions } from 'vuex';

export default {
  name: 'EmailNotificationSettings',

  data: () => ({
    newEmail: '',
    loading: false
  }),

  components: {
    EmailNotificationSettingBox
  },

  props: {
    settings: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    settingOptions() {
      return {
        [EmailNotificationSettingOption.WORK_QUEUE]: {
          title: 'settings.emailNotifications.settings.workQueue.title',
          tooltip: 'settings.emailNotifications.settings.workQueue.tooltip'
        },
        [EmailNotificationSettingOption.BNET_STATUS]: {
          title: 'settings.emailNotifications.settings.bnetStatus.title',
          tooltip: 'settings.emailNotifications.settings.bnetStatus.tooltip'
        },
        [EmailNotificationSettingOption.BNET_PURCHASE_STATUS]: {
          title: 'settings.emailNotifications.settings.bnetPurchaseStatus.title',
          tooltip: 'settings.emailNotifications.settings.bnetPurchaseStatus.tooltip',
          condition: this.isFactoring
        }
      };
    },
    existentSettingEmails() {
      return this.settings.reduce((emails, setting) => {
        emails.push(setting.email);
        return emails;
      }, []);
    },
    emailToDelete: {
      get() {
        return this.getQueryParam(this.queryParam.DELETE);
      },
      set(value) {
        this.setQueryParam(this.queryParam.DELETE, value);
      }
    }
  },

  watch: {
    emailToDelete: {
      handler() {
        if (
          !this.settings.some(
            (setting) => setting.email === this.getQueryParam(this.queryParam.DELETE)
          )
        ) {
          this.setQueryParam(this.queryParam.DELETE, undefined, true);
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchEmailNotificationSettings', 'setEmailNotificationSettings']),

    async submitChanges(changedSetting, changedIndex) {
      this.loading = true;
      const payload = [...this.settings];
      payload[changedIndex] = { ...this.settings[changedIndex], setting: changedSetting };

      const { error } = await this.setEmailNotificationSettings(payload);
      this.loading = false;

      if (error) {
        return showSnackbar({ text: 'changesNotSaved' });
      }

      this.$emit('update:settings', payload);
      showSnackbar({ text: 'changesSaved', color: 'success', icon: '$iconCheck' });
    },

    async addSetting() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const payload = [
        ...this.settings,
        {
          email: this.newEmail,
          setting: EMAIL_NOTIFICATION_SETTING_OPTIONS.reduce((newSettings, option) => {
            return { ...newSettings, [option]: false };
          }, {})
        }
      ];

      const { error } = await this.setEmailNotificationSettings(payload);
      this.loading = false;

      if (error) {
        return showSnackbar({ text: 'changesNotSaved' });
      }

      this.$emit('update:settings', payload);
      this.newEmail = '';
      showSnackbar({
        text: 'settings.emailNotifications.addedEmail',
        color: 'success',
        icon: '$iconCheck'
      });
    },

    async deleteSetting() {
      this.loading = true;
      const payload = [...this.settings].filter((setting) => setting.email !== this.emailToDelete);

      const { error } = await this.setEmailNotificationSettings(payload);
      this.loading = false;

      if (error) {
        return showSnackbar({ text: 'changesNotSaved' });
      }

      this.$emit('update:settings', payload);
      showSnackbar({ text: 'changesSaved', color: 'success', icon: '$iconCheck' });
      this.emailToDelete = undefined;
    }
  }
};
</script>

<style scoped lang="scss">
.settings__add-btn {
  background-color: var(--c-primary) !important;
  color: var(--c-background-text) !important;
  margin-block: 8px;
}
</style>
