<template>
  <v-container fluid :class="{ 'd-flex justify-center align-center': loading || error }">
    <app-error-alert v-if="error" />
    <app-spinner v-else-if="loading" />
    <template v-else>
      <v-row>
        <v-col :cols="isMobile ? 12 : 6" class="tab__col">
          <email-notification-settings :settings.sync="emailNotificationSettings" />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import EmailNotificationSettings from '@/modules/User/components/MasterData/EmailNotificationSettings';

export default {
  name: 'TabSettings',

  components: { EmailNotificationSettings },

  data: () => ({
    emailNotificationSettings: [],
    error: false,
    loading: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['fetchEmailNotificationSettings'])
  },

  async created() {
    this.loading = true;
    const { settings, error } = await this.fetchEmailNotificationSettings();

    if (error) {
      return (this.error = true);
    }

    this.emailNotificationSettings = settings;
    this.loading = false;
  }
};
</script>
