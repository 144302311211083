<template>
  <div>
    <app-dialog
      v-model="open"
      title="users.deleteUser"
      primary-action-text="navigation.yesDelete"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      @submit="onSubmit"
    >
      <p class="mb-4">{{ $t('settings.companyUsers.deleteUser') }}</p>

      <app-input-row dense label="name">
        <disabled-input-box>{{ user.name }}</disabled-input-box>
      </app-input-row>
      <app-input-row dense label="email">
        <disabled-input-box>{{ user.email }}</disabled-input-box>
      </app-input-row>
      <app-input-row dense label="users.role">
        <disabled-input-box>{{ user.role | formatRole }}</disabled-input-box>
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="users.deletedUser"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'DeleteCompanyUserDialog',

  mixins: [ShowDialogMixin],

  components: {
    DisabledInputBox
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['deleteCompanyUser']),

    async onSubmit() {
      this.loading = true;

      const { error } = await this.deleteCompanyUser({ userIdentifier: this.user.id });

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
